<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          class="btn btn-secondary"
          @click="
            $router.push({
              name: 'purchases-items-edit',
              params: { reference: purchase.reference },
            })
          "
        >
          <i class="bi bi-pencil-square"></i>
          Modifer les articles
        </button>
      </div>
    </div>
    <hr />
    <div class="row justify-content-between">
      <div class="col d-flex">
        <div class="col-auto">Total Quantite :</div>
        <div class="col">
          <span
            v-if="numberItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ numberItems }}
          </span>
          Élément
        </div>
      </div>

      <div class="col d-flex">
        <div class="col-auto">Quantité totale :</div>
        <div class="col">
          <span
            v-if="totalQuantityItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalQuantityItems }}
          </span>
        </div>
      </div>

      <div class="col d-flex">
        <div class="col-auto">Montant Total (TTC*) :</div>
        <div class="col">
          <span
            v-if="totalPriceTTCItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalPriceTTCItems }}
          </span>
          DH
        </div>
      </div>
    </div>
    <hr />
    <br />
    <div class="row align-items-end">
      <div class="col">
        <label for="">Fournisseur <span class="text-danger">*</span> </label>
        <select v-model="purchase.supplier_reference" class="form-select">
          <option value=""></option>
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
          >
            {{ supplier.society + " " + supplier.society }}
          </option>
        </select>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button
          @click="$router.push({ name: 'suppliers-create' })"
          class="btn btn-secondary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un fournisseur
        </button>
      </div>

      <div class="col-3">
        <label for="">Date <span class="text-danger">*</span></label>
        <input type="date" v-model="purchase.date" class="form-control" />
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-3">
        <label for="">
          Bon de Commande Nº <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          v-model="purchase.orderNumber"
          class="form-control"
        />
      </div>

      <div class="col-3">
        <label for="">Bon Livraison Nº </label>
        <input
          type="text"
          v-model="purchase.deliveryOrderNumber"
          class="form-control"
        />
      </div>
      <div class="col-3">
        <label for="">Facture Nº <span class="text-danger">*</span></label>
        <input
          type="text"
          v-model="purchase.invoiceNumber"
          class="form-control"
        />
      </div>

      <div class="col-3">
        <label for="">Montant Payé <span class="text-danger">*</span> </label>
        <input type="text" v-model="purchase.amountPaid" class="form-control" />
      </div>

      <div class="col-3" v-if="purchase.amountPaid > 0">
        <label for=""
          >Mode de paiement <span class="text-danger">*</span></label
        >
        <select v-model="purchase.paymentMethod" class="form-select">
          <option value="Chèque">Chèque</option>
          <option value="Espèces">Espèces</option>
          <option value="Virement bancaire">Virement bancaire</option>
        </select>
      </div>

      <div class="col-3" v-if="purchase.paymentMethod == 'Chèque'">
        <label for="">Chèque Nº <span class="text-danger">*</span> </label>
        <input
          type="text"
          v-model="purchase.checkNumber"
          class="form-control"
        />
      </div>

      <div class="col-3" v-if="purchase.paymentMethod == 'Virement bancaire'">
        <label for="">Virement Nº <span class="text-danger">*</span> </label>
        <input
          type="text"
          v-model="purchase.transferNumber"
          class="form-control"
        />
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à l'achat (Fournisseur,
            Quantité totale, Mantant totale, Nombre d'articles, ...) et
            correctes. <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-success"
          @click="save(purchase)"
          :disabled="!accept ? true : false"
        >
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        (<span class="text-danger">*</span>) : Ce champ est obligatoire.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return { accept: false };
  },

  async mounted() {
    await this.$store.dispatch("supplier/getAll");
    await this.$store.dispatch("purchase/show", this.$route.params.reference);
  },
  computed: {
    ...mapGetters("purchase", {
      purchase: "getPurchase",

      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
    }),
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("supplier/getAll");
      await this.$store.dispatch("purchase/show", this.$route.params.reference);
    },
    async save(data) {
      await this.$store.dispatch("purchase/update", data);
      await setTimeout((this.accept = false), 2000);
    },
  },
};
</script>
